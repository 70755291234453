import React from "react";
import Accordion from "~/components/accordion";
import ContentWrapper from "~/components/content-wrapper";
import H1 from "~/components/Typography/H1";
import LessonLayout from "~/layouts/lesson";
import Button from "~/components/button";

const Page = () => {
  return (
    <LessonLayout
      current_step={11}
      lesson={"Lesson 4"}
      color={"ms"}
      total_count={15}
      module_title={undefined}
    >
      <ContentWrapper>
        <H1 underline>Step 11</H1>

        <p>The FTC works to help consumers spot, stop, and avoid deceptive or unfair claims about OTC drugs and dietary supplements.</p>
        <p>People can check the FTC website to see if the FTC has found problems with a company’s advertising or claims. The links below give examples of warnings and notices the FTC has put out in response to inappropriate marketing. Take a look at one or more of these examples.</p>


        <p>People can also make complaints on the FTC website about unfair advertising of OTC drugs or dietary supplements.</p>

        <div className="flex flex-wrap md:flex-nowrap place-content-between">
          <Button
            action="secondary"
            color="purple"
            href="https://www.ftc.gov/news-events/press-releases/2020/04/thrive-supplement-marketer-agrees-preliminary-order-barring-him"
            icon="external"
            title="Example 1"
          >
            Example 1
          </Button>
          <Button
            action="secondary"
            color="purple"
            href="https://www.ftc.gov/news-events/press-releases/2014/01/sensa-three-other-marketers-fad-weight-loss-products-settle-ftc"
            icon="external"
            title="Example 2"
          >
            Example 2
          </Button>
          <Button
            action="secondary"
            color="purple"
            href="https://www.ftc.gov/news-events/blogs/business-blog/2020/03/ftc-takes-unproven-health-claims-own-dorsements"
            icon="external"
            title="Example 3"
          >
            Example 3
          </Button>
        </div>

        <Accordion className="mt-5" headerText="Hint" open={false}>
          These links show examples of how the FTC responds to inappropriate marketing. These give you an idea of how the FTC alerts companies as well as the public about inappropriate claims. You do not need to memorize any details about the specific products.
        </Accordion>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

